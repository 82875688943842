var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.visible = true}}},[_c('a-button',{staticStyle:{"background":"#ff8c18","border":"1px solid #ff8c18"},attrs:{"type":"primary","icon":"plus"}},[_vm._v(" 添加 ")])],1),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"添加酒水","footer":null},on:{"cancel":function($event){_vm.visible = false}}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"类型","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'typeInv',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'typeInv',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],on:{"change":_vm.onTypeChange}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"子类型","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'typeSub',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'typeSub',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],on:{"change":_vm.onSubTypeChange}},_vm._l((_vm.subTypeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"酒水品名","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'id',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'id',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],on:{"change":_vm.onNameChange}},_vm._l((_vm.list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"库存","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['quantity']),expression:"['quantity']"}],staticStyle:{"width":"100%"},attrs:{"min":0,"disabled":true}})],1),_c('a-form-item',{attrs:{"label":"单位","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unit']),expression:"['unit']"}],attrs:{"disabled":true}},_vm._l((_vm.unitList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"单价","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unitPrice']),expression:"['unitPrice']"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat,"disabled":true,"min":0}})],1),_c('a-form-item',{attrs:{"label":"申领数量","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'needQuantity',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'needQuantity',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }